import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// import Vant from "vant";
// import { Swipe, SwipeItem } from "vant";
// import "vant/lib/index.css";

// Vue.use(Swipe);
// Vue.use(SwipeItem);
axios.defaults.baseURL = "/api";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

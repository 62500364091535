<template>
  <div class="home">铭派科技</div>
</template>

<script>
export default {
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>

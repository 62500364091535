import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  //保密服务
  {
    path: "/serviceItems/bm",
    name: "bm",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ServiceItems/BM/BM.vue"),
  },
  //屏蔽建设
  {
    path: "/serviceItems/shieldConstruction",
    name: "shieldConstruction",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/ShieldConstruction/ShieldConstruction.vue"
      ),
  },
  //软件开发
  {
    path: "/serviceItems/softwareDevelopment",
    name: "softwareDevelopment ",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/SoftwareDevelopment/SoftwareDevelopment.vue"
      ),
  },
  //总体集成
  {
    path: "/serviceItems/integration",
    name: "integration",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/IntegrationView/IntegrationView.vue"
      ),
  },
  //路线导航
  {
    path: "/advisoryService/navigation",
    name: "Navigation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdvisoryService/NavigationView.vue"
      ),
  },
  //联系我们
  {
    path: "/advisoryService/ContactMe",
    name: "contactMe",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdvisoryService/ContactMe.vue"
      ),
  },
  //招聘岗位
  {
    path: "/advisoryService/recruitmentPositions",
    name: "recruitmentPositions",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdvisoryService/RecruitmentPositions.vue"
      ),
  },
  //行业资讯
  {
    path: "/advisoryService/industryInformation",
    name: "industryInformation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AdvisoryService/IndustryInformation.vue"
      ),
  },
  //公司简介
  {
    path: "/aboutUs/companyProfile",
    name: "companyProfile",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/CompanyProfile/CompanyProfile.vue"
      ),
  },
  //企业荣誉
  {
    path: "/aboutUs/corporateHonors",
    name: "corporateHonors",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/CorporateHonors.vue"
      ),
  },
  // 分支机构
  {
    path: "/aboutUs/branchOffice",
    name: "branchOffice",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/BranchOffice.vue"
      ),
  },
  // 企业实力
  {
    path: "/aboutUs/enterpriseStrength",
    name: "enterpriseStrength",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/EnterpriseStrength/EnterpriseStrength.vue"
      ),
  },
  //二级页面
  //保密监理
  {
    path: "/serviceItems/bm/BMSupervision",
    name: "BMSupervision",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/BM/components/BMSupervision.vue"
      ),
  },
  //保密咨询
  {
    path: "/serviceItems/bm/BMConsult",
    name: "BMConsult",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/BM/components/BMConsult.vue"
      ),
  },
  //保密教育
  {
    path: "/serviceItems/bm/BMEducation",
    name: "BMEducation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/BM/components/BMEducation.vue"
      ),
  },
  //保密建设
  {
    path: "/serviceItems/bm/BMBuild",
    name: "BMBuild",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/BM/components/BMBuild.vue"
      ),
  },
  //企业团队
  {
    path: "/aboutUs/enterpriseStrength/enterpriseTeam",
    name: "EnterpriseTeam",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/EnterpriseStrength/components/EnterpriseTeam.vue"
      ),
  },
  //企业资质
  {
    path: "/aboutUs/enterpriseStrength/enterpriseQualification",
    name: "EnterpriseQualification",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/EnterpriseStrength/components/EnterpriseQualification.vue"
      ),
  },
  //企业科研
  {
    path: "/aboutUs/enterpriseStrength/enterpriseScientificResearch",
    name: "EnterpriseScientificResearch",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/EnterpriseStrength/components/EnterpriseScientificResearch.vue"
      ),
  },
  //军用软件
  {
    path: "/serviceItems/softwareDevelopment/military",
    name: "Military",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/SoftwareDevelopment/components/SDMilitary.vue"
      ),
  },
  //商用软件
  {
    path: "/serviceItems/softwareDevelopment/commercial",
    name: "Commercial",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/SoftwareDevelopment/components/SDCommercial.vue"
      ),
  },
  //装饰工程
  {
    path: "/serviceItems/integration/decorate",
    name: "Decorate",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/IntegrationView/components/IntegrationDecorate.vue"
      ),
  },
  //智能工程
  {
    path: "/serviceItems/integration/intelligent",
    name: "Intelligent",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/IntegrationView/components/IntegrationIntelligent.vue"
      ),
  },
  //消防工程
  {
    path: "/serviceItems/integration/fire",
    name: "Fire",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ServiceItems/IntegrationView/components/IntegrationFire.vue"
      ),
  },
  //创始人
  {
    path: "/aboutUs/companyProfile/founder",
    name: "founder",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/CompanyProfile/components/FounderView.vue"
      ),
  },
  //企业文化
  {
    path: "/aboutUs/companyProfile/corporateCulture",
    name: "corporateCulture ",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/CompanyProfile/components/CorporateCulture.vue"
      ),
  },
  //公司简介三级
  {
    path: "/aboutUs/companyProfile/companyProfileThreeLevel",
    name: "companyProfileThreeLevel",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/CompanyProfile/components/CompanyProfileThreeLevel.vue"
      ),
  },
  // 四级页面
  {
    path: "/aboutUs/enterpriseStrength/enterpriseQualification/AAA",
    name: "AAA",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EnterpriseQualification/AAA.vue"
      ),
  },
  {
    path: "/aboutUs/enterpriseStrength/enterpriseQualification/IOS",
    name: "IOS",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EnterpriseQualification/IOS.vue"
      ),
  },
  {
    path: "/aboutUs/enterpriseStrength/enterpriseQualification/industry",
    name: "IndustryView",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EnterpriseQualification/IndustryView.vue"
      ),
  },
  {
    path: "/aboutUs/enterpriseStrength/enterpriseQualification/constructionCommittee",
    name: "constructionCommittee",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/EnterpriseQualification/ConstructionCommittee.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有savedPosition，就用它，没有的话就返回顶部
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;
